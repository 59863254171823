var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"* Evento","popup-label":"Selecione um Evento","id":_vm.value.idCompEvento,"descricao":_vm.value.descCompEvento,"value":_vm.evento,"loader-fn":_vm.loaderEventos,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEvento", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Evento encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Ano Competência","rules":[
              this.$validators.number.required,
              function (v) { return this$1.$validators.number.greaterThanOrEquals(v, this$1.anoPermitidoInicio()); },
              function (v) { return this$1.$validators.number.lessThanOrEquals(v, this$1.anoPermitidoFim()); }
          ],"type":"number"},model:{value:(_vm.value.anoCompetencia),callback:function ($$v) {_vm.$set(_vm.value, "anoCompetencia", $$v)},expression:"value.anoCompetencia"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"* Etapa","popup-label":"Selecione uma Etapa","id":_vm.value.idCompEtapa,"descricao":_vm.value.descCompEtapa,"value":_vm.etapa,"loader-fn":_vm.loaderEtapas,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEtapa", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEtapa", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Etapa encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Data Inicio Insc.","dense":"","type":"date","rules":[
            this.$validators.string.required,
            function (v) { return this$1.$validators.string.lessThanOrEquals(v, 10); }
        ]},model:{value:(_vm.value.dtInicioInscricao),callback:function ($$v) {_vm.$set(_vm.value, "dtInicioInscricao", $$v)},expression:"value.dtInicioInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Data Fim Insc.","dense":"","type":"date","rules":[
              this.$validators.string.required,
              function (v) { return this$1.$validators.string.lessThanOrEquals(v, 10); }
          ]},model:{value:(_vm.value.dtFimInscricao),callback:function ($$v) {_vm.$set(_vm.value, "dtFimInscricao", $$v)},expression:"value.dtFimInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"* Status","type":_vm.lookups.status,"hidden-clear":false},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }