<template>
<v-row>
  <v-col cols="12" sm="4">
    <search-box
        label="* Evento"
        popup-label="Selecione um Evento"
        :id.sync="value.idCompEvento"
        :descricao.sync="value.descCompEvento"
        :value="evento"
        :loader-fn="loaderEventos"
        item-key="id"
        item-text="descricao"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Evento encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field dense label="* Ano Competência" 
          v-model="value.anoCompetencia"
          :rules="[
              this.$validators.number.required,
              v => this.$validators.number.greaterThanOrEquals(v, this.anoPermitidoInicio()),
              v => this.$validators.number.lessThanOrEquals(v, this.anoPermitidoFim())
          ]" type="number"/>
  </v-col>  
  <v-col cols="12" sm="4">
    <search-box
        label="* Etapa"
        popup-label="Selecione uma Etapa"
        :id.sync="value.idCompEtapa"
        :descricao.sync="value.descCompEtapa"
        :value="etapa"
        :loader-fn="loaderEtapas"
        item-key="id"
        item-text="descricao"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma Etapa encontrada
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field label="* Data Inicio Insc."
        dense
        type="date"
        v-model="value.dtInicioInscricao"
        :rules="[
            this.$validators.string.required,
            v => this.$validators.string.lessThanOrEquals(v, 10)
        ]"/>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field label="* Data Fim Insc."
          dense
          type="date"
          v-model="value.dtFimInscricao"
          :rules="[
              this.$validators.string.required,
              v => this.$validators.string.lessThanOrEquals(v, 10)
          ]"/>
  </v-col>
  <v-col cols="12" sm="4">
    <lookup-dominio label="* Status"
                    v-model="value.status"
                    :type="lookups.status"
                    :hidden-clear="false"/>
  </v-col>
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllEventos} from "../../../api/eventos";
import {findAll as findAllEtapas} from "../../../api/etapas";
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import moment from 'moment';

export default {
  name: "CompeticaoForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
    }
  },
  computed: {
    evento() {
      if(this.value.idCompEvento == null) return null;
      return {
        id: this.value.idCompEvento,
        descricao: this.value.descCompEvento
      }
    },
    etapa() {
      if(this.value.idCompEtapa == null) return null;
      return {
        id: this.value.idCompEtapa,
        descricao: this.value.descCompEtapa
      }
    },
    
  },
  methods: {
    loaderEventos(page, search) {
      return findAllEventos(page, {
        descricao: search
      })
    },
    loaderEtapas(page, search) {
      return findAllEtapas(page, {
        descricao: search
      })
    }, 
    anoPermitidoInicio() {
        return parseInt(moment().format('YYYY'))-2;
    }, 
    anoPermitidoFim() {
        return parseInt(moment().format('YYYY'))+2;
    },     
  },
}
</script>

<style scoped>

</style>